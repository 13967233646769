<template>
  <div>
    <v-menu :nudge-width="100" offset-y v-if="user" flat>
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex align-center" v-bind="attrs" v-on="on">
          <div v-if="!$vuetify.breakpoint.mobile" class="pl-2 font-medium">
            {{ user.fullName }}
          </div>
          <v-avatar color="pyramir" v-if="user.imageUrl" size="36">
            <v-img :src="user.imageUrl" width="90"></v-img>
          </v-avatar>
          <v-avatar
            v-else
            size="36"
            color="primary"
            class="white--text font-weight-bold"
          >
            <span>{{ user?.fullName?.split(" ")[0]?.charAt(0) }}</span>
            <span>{{
              user?.fullName?.split(" ")[1]
                ? user?.fullName?.split(" ")[1]?.charAt(0)
                : ""
            }}</span>
          </v-avatar>
        </div>
      </template>

      <v-card flat class="text-center pa-10">
        <v-img
          class="mx-auto"
          src="@/assets/img/user.png"
          alt="user"
          width="90"
        ></v-img>
        <h3 class="text-center mt-4 primary--text">{{ user.fullName }}</h3>
        <v-card-text>
          <div class="primary--text mt-n2 text-decoration-underline">
            {{ user.userName }}
          </div>
          <div class="grey--text mt-n2">{{ user.email }}</div>
          <!-- <v-btn to="/myProfile" text disabled block color="primary" class="my-2">ادارة حسابك الشخصي</v-btn> -->
        </v-card-text>

        <v-divider></v-divider>

        <v-btn block outlined color="error" @click="logOut()">تسجيل خروج</v-btn>
      </v-card>
    </v-menu>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      dailogchangePassword: false,
      user: null,
    };
  },

  computed: {
    ...mapState("auth", ["userData"]),
  },

  methods: {
    async logOut() {
      this.$store.dispatch("auth/logout");
      this.$store.dispatch("toastification/setToast", {
        message: `تم تسجيل الخروج`,
      });
      this.$router.push("/login");
    },

    async unsubscribeFromTopic() {
      this.$messaging()
        .unsubscribeFromTopic("registrationTokens", "admin")
        .then(function (response) {
          // See the MessagingTopicManagementResponse reference documentation
          // for the contents of response.
          console.log("Successfully unsubscribed from topic:", response);
        })
        .catch(function (error) {
          console.log("Error unsubscribing from topic:", error);
        });
    },
  },
  created() {
    if (this.userData.accessToken) {
      this.user = this.userData.userData;
    }
    // console.log(this.user);
  },
};
</script>
<style lang=""></style>
