<template>
  <div>
    <v-app-bar
      v-if="!$route.meta.hideNavigation"
      :key="$route.path"
      :color="$vuetify.theme.dark ? '#2d3248' : 'white'"
      class="shadow"
      flat
      dense
    >
      <v-btn @click="setMiniDrawer()" small icon depressed fab class="mx-2">
        <v-icon>{{
          mini_drawer == true ? "mdi mdi-text" : "mdi mdi-menu "
        }}</v-icon>
      </v-btn>

      <v-breadcrumbs :items="$route.meta.breadcrumbs" dir="rtl" divider="\">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item :href="item.href" :disabled="item.disabled">
            <h5 :class="item.disabled ? 'grey--text' : ''">{{ item.text }}</h5>
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>

      <v-spacer></v-spacer>
      <!-- <locale v-if="!$vuetify.breakpoint.mobile" /> -->
      <theme-changer v-if="!$vuetify.breakpoint.mobile" />
      <fullscreen v-if="!$vuetify.breakpoint.mobile" />
      <v-divider vertical inset class="ml-2" />
      <user-profile></user-profile>

      <BackButton
        typeIcon="mdi-chevron-left"
        fab="fab"
        small="small"
        text="text"
        class="ml-n3 mr-1"
        v-if="$route.path != '/'"
      />
    </v-app-bar>
    <drawer
      :drawer="drawer"
      v-if="!$route.meta.hideNavigation || !$route.meta.hideDrawer"
    />
  </div>
</template>

<script>
import UserProfile from "@/components/user/userProfile.vue";
import Fullscreen from "./Fullscreen.vue";
// import Locale from "./Locale.vue";
import ThemeChanger from "./ThemeChanger.vue";
import drawer from "./Drawer.vue";
import { mapGetters, mapState } from "vuex";
import BackButton from "../components/BackButton.vue";

export default {
  name: "layout",
  components: {
    drawer,
    UserProfile,
    Fullscreen,
    ThemeChanger,
    // Locale,
    BackButton,
  },

  data() {
    return {
      drawer: true,
    };
  },

  computed: {
    ...mapState("drawer", ["mini_drawer"]),
  },

  methods: {
    setMiniDrawer() {
      this.$store.dispatch("drawer/toggleMiniDrawer");
    },
  },
};
</script>
