<template>
    <div>
        <v-btn @click="setTheme()" icon small>
            <v-icon class="medium-icon">{{ $vuetify.theme.isDark == true ? 'mdi-lightbulb-on-outline' : 'fi fi-rr-moon' }}</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    data() {
        return {
            themeCurent: null,
        };
    },
    mounted() {
        const theme = localStorage.getItem("dark_theme");
        if (theme) {
            if (theme == "true") {
                this.$vuetify.theme.dark = true;
                this.themeCurent = true;
            } else {
                this.$vuetify.theme.dark = false;
                this.themeCurent = false;
            }
        }
    },
    methods: {
        setTheme() {
            this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
            localStorage.setItem(
                "dark_theme",
                this.$vuetify.theme.dark.toString()
            );
        },
    },
};
</script>
