<template>
    <div>
        <v-btn :outlined="outlined" @click="back" :small="small" :fab="fab" :text="text">
            <v-icon>{{ typeIcon ? typeIcon : 'mdi-arrow-left' }}</v-icon>
        </v-btn>
    </div>
</template>
<script>
export default {
    props: ["small", "fab", "text", "typeIcon", "outlined"],
    methods: {
        back() {
            this.$router.go(-1);
        },
    },
};
</script>
