<template>
    <div class="mx-2">
        <v-btn @click="fullscreen ? exitFullScreen() : fullScreen()" icon small>
            <v-icon class="medium-icon">{{fullscreen ? 'zoom_out_map' : 'crop_free'}}</v-icon>
        </v-btn>
    </div>
</template>

<script>
export default {
    data() {
        return {
            fullscreen: false,
        };
    },
    methods: {
        fullScreen() {
            document.documentElement
                .requestFullscreen()
                .then(() => (this.fullscreen = true));
        },
        exitFullScreen() {
            document.exitFullscreen().then(() => (this.fullscreen = false));
        },
    },
};
</script>
