<template>
    <div>
        <v-navigation-drawer width="260" :color="$vuetify.theme.dark ? '#2e3349' : ''" v-model="$store.state.drawer.mini_drawer" 
            app floating class="" :right="$vuetify.rtl">
            <div class="pa-4 text-center mx-auto">
                <v-avatar size="120" color="primary">
                    <v-avatar size="110" color="white" class="pa-10">
                        <v-avatar size="102" color="white" class="pa-10">
                            <img src="@/assets/img/logo2.jpeg" width="100%">
                        </v-avatar>
                    </v-avatar>
                </v-avatar>
                <div class="pa-4">
                    <span>شركة جوهرة سما بابل للاستثمار</span>
                </div>
            </div>
            <itemDrawer />
        </v-navigation-drawer>
    </div>
</template>

<script>
import ItemDrawer from "./ItemDrawer.vue";

export default {
    name: "Drawer",
    components: {
        ItemDrawer,
    },

    methods: {
        logOut() {
            this.$store.dispatch("auth/logout");
            this.$router.push("/login");
            this.$store.dispatch("toastification/setToast", {
                message: `تم تسجيل الخروج`,
                type: "error",
            });
        },
    },
};
</script>
