<template>
    <v-list nav>
        <div v-for="(item, i) in items" :key="i">
            <v-list-group exact class="my-3" :key="i" v-if="item.children" v-model="item.active">
                <v-icon slot="prependIcon">{{ item.icon }}</v-icon>
                <template v-slot:activator>
                    <v-list-item-content class="mr-n6">
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item active-class="primary--text" class="pr-6" v-for="(subItem, ind) in item.children" :key="ind"
                    :to="subItem.to">
                    <v-icon small right>mdi-circle-outline</v-icon>
                    <div>{{ subItem.title }}</div>
                </v-list-item>
            </v-list-group>

            <v-list-item v-else :to="item.to" active-class="secondary--text">
                <v-icon size="22">{{ item.icon }}</v-icon>
                <v-list-item-title class="mr-3">{{ item.title }}</v-list-item-title>
            </v-list-item>
        </div>
    </v-list>
</template>

<script>
export default {
    data() {
        return {
            items: [],
        };
    },

    created() {
        this.setItems();
        setTimeout(() => {
            var childs = this.items.filter((item) => {
                return (
                    item.children &&
                    item.children.some((it) => {
                        return it.to == this.$route.path;
                    })
                );
            });
            if (childs && childs.length > 0) {
                childs[0].active = true;
            }
        }, 200);
    },

    methods: {
        setItems() {
            this.items.push(
                // {
                //   title: "Home",
                //   icon: "fi fi-rr-home",
                //   to: "/area",
                //   divider: false,
                //   auth: [1, 3],
                // },

                {
                    title: "فروعنا",
                    icon: "fi fi-rr-code-branch",
                    to: "/branches",
                    divider: false,
                    auth: [1, 3],
                },
                {
                    title: "الاسئلة الشائعة",
                    icon: "fi fi-rr-question-square",
                    to: "/commonQuestions",
                    divider: false,
                    auth: [1, 3],
                },
                {
                    title: "مشاريعنا",
                    icon: "fi fi-rr-briefcase",
                    to: "/projects",
                    divider: false,
                    auth: [1, 3],
                },
                {
                    title: "اخبار الشركة",
                    icon: "fi fi-rr-radio-alt",
                    to: "/news",
                    divider: false,
                    auth: [1, 3],
                },
                {
                    title: "الاعلانات",
                    icon: "fi fi-rr-ad",
                    to: "/slider",
                    divider: false,
                    auth: [1, 3],
                },
                {
                    title: "الاستثمارات",
                    icon: "fi fi-rr-users-alt",
                    to: "/Investments",
                    divider: false,
                    auth: [1, 3],
                },
                {
                    title: "المستخدمين",
                    icon: "fi fi-rr-settings",
                    to: "/users",
                    divider: false,
                    auth: [1],
                },
                {
                    title: "تذكيرات",
                    icon: "fi fi-rr-megaphone",
                    to: "/profitRolls",
                    divider: false,
                    auth: [1],
                }
            );
        },

        checkAuth(item) {
            if (item.auth.length == 0) return true;
            for (var i = 0; i < item.auth.length; i++) {
                if (
                    this.$store.state.auth.userData.userData.group.key == item.auth[i]
                ) {
                    return true;
                }
            }
            return false;
        },
    },
    beforeCreate() { },
};
</script>

<style scoped>
.divider--text {
    font-size: 10px;
    color: grey;
    opacity: 0.6;
}

.nav-item-icon {
    font-size: 18px;
}
</style>
